import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for fetching inventory list from the new endpoint
export const fetchInventoryList = createAsyncThunk("fetchInventoryList", async ({ channel_id, filters, page, limit }, { rejectWithValue }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		status: filters?.status ?? "",
		type_id: filters?.type_id ?? "",
	});

	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}backoffice/channel/${channel_id}/stocks?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const RewardInventoryListSlice = createSlice({
	name: "RewardInventoryListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchInventoryList.pending, (state) => {
			state.isLoading = true;
			state.error = null; // Clear any previous error on new request
		});

		builder.addCase(fetchInventoryList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload.docs; // Adjust to match the data structure from response if necessary
			state.page = action.payload.currentPage; // Update based on the response
			state.total = action.payload.count; // Update based on the response
		});

		builder.addCase(fetchInventoryList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload || "Unknown error occurred";
		});
	},
});

export default RewardInventoryListSlice.reducer;
